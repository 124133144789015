.normal-text-style {
  color: #212636;
  font-weight: 400;
}

.logo-style {
  width: 100px;
  height: 20px;
  margin: 10px;
}
