#title {
  text-align: center;
}

#tableContainer {
  width: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

#user {
  border-collapse: collapse;
  border: 1px solid #E6E8ED;
  width: 100%;
  word-wrap: break-word;
}

#user td,
#user th {
  border: 1px solid #E6E8ED;
  padding: 8px;
  max-width: 230px;
  min-width: 50px;
  text-align: center;
  color: #212636;
  font-weight: 300;
}

#user th {
  padding: 8px;
  text-align: center;
  background-color: #3B455A;
  color: #F5F5F5;
  font-weight: 500;
}

.opration {
  text-align: center;
}

.button {
  border: none;
  outline: none;
  font-size: 11px;
  font-family: "Quicksand", sans-serif;
  color: #f44336;
  padding: 3px 10px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f44336;
  background-color: transparent;
  margin-right: 10px;
  font-weight: 300;
}

.button:active {
  border: 1px solid blue;
}

.sort-icon-style {
  margin: 5px;
}

.normal-text-style {
  color: #212636;
  font-weight: 400;
}

.container-style {
  margin: 20px;
}