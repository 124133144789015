.inactive-text-style {
  color: #96A5B9;
  font-weight: 500;
}

.active-text-style {
  color: #00A0FF;
  font-weight: 500;
}

.svg-inactive-text-style {
  fill: #96A5B9;
}

.svg-active-text-style {
  fill: #00A0FF;
}

.logo-style {
  width: 100px;
  height: 20px;
  margin-bottom: 20px;
}

.sidebar-container {
  background-color: #283041;
}

.svg:hover {
  fill: #212636;
}