.small-text {
  font-size: 0.875rem;
  color: #96A5B9;
  letter-spacing: 0.1;
}

.xs-text {
  font-size: 0.775rem;
  color: #96A5B9;
  letter-spacing: 0.1;
}

.active-text {
  font-size: 1.2rem;
  color: #00A0FF;
  font-family: "Noto Sans";
  letter-spacing: 0.7px;
  text-align: center;
  vertical-align: middle;
}

.container {
  padding: 5px;
}

.input {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 1.8rem;
  letter-spacing: 0.2;
  font-family: "Noto Sans";
  text-align: center;
  line-height: 48px;
  margin-right: 5px;
  text-transform: uppercase;
  color: #212636;
  border-bottom: 2px solid #D8D8D8;
  outline: none;
}

.active-button {
  font-size: 14px;
  font-family: "Noto Sans";
  letter-spacing: 0.1px;
  line-height: 22px;
  font-weight: 600;
  color: #00A0FF;
  border: 1px solid #00A0FF;
}

.active-button:disabled {
  color: #96A5B9;
  border: 1px solid #96A5B9;
}

.google-login-container {
  width: 25%;
}

@media (max-width: 1200px) and (min-width: 600px) {
  .google-login-container {
    width: 35%;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .google-login-container {
    width: 55%;
  }
}

@media (max-width: 600px) {
  .google-login-container {
    width: 80%;
  }
}