.filter-input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}

/* clears the 'X' from Internet Explorer */
.filter-input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.filter-input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
.filter-input[type="search"]::-webkit-search-decoration,
.filter-input[type="search"]::-webkit-search-cancel-button,
.filter-input[type="search"]::-webkit-search-results-button,
.filter-input[type="search"]::-webkit-search-results-decoration {
  display: none;
}