.active-text {
  font-size: 1.5rem;
  letter-spacing: 0.1;
}

.large-text {
  font-size: 3rem;
  letter-spacing: 0.1;
}

.small-text {
  font-size: 0.875rem;
  color: #96A5B9;
  letter-spacing: 0.1;
}

.logo-style__page-not-found {
  width: 200px;
  height: 30px;
  margin-bottom: 30px;
}
