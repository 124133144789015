@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Noto Sans', serif;
}

@layer components {
    .hero-heading {
        @apply text-white text-heading_1 font-medium
    }

    .expertise-tag {
        @apply border border-gray-dark rounded-full rounded-bl-none p-1 px-2 mr-2 text-xs bg-tag mb-2
    }

    .bot-badge {
        @apply rounded-full p-0.5 px-1.5 text-xs bg-primary text-white
    }
}

@layer utilities {
    .content-height-with-navbar {
        @apply h-[calc(100%_-_4rem_-_1px)]
    }

    .title-base {
        @apply text-title text-title-color font-medium
    }

    .title-primary {
        @apply text-title text-primary font-medium
    }

    .nav-button-text {
        @apply text-nav-button text-base font-normal
    }

    .body-base-text-italic {
        @apply text-body text-base font-normal italic
    }

    .body-base-text {
        @apply text-body text-base font-light
    }

    .support-text {
        @apply text-support text-gray-dark font-normal
    }

    .support-text-primary {
        @apply text-support text-primary font-normal
    }
}

.light {
    --background: #FFFFFF;
    --background-secondary: #F5F5F5;
    --background-primary: #FFFFFF;
    --background-dark: #171C2D;

    --primary: #00A0FF;
    --accent: #0BC7E0;
    --error: #F03C11;

    --text-base: #212636;
    --text-title: #000000;
    --shadow: #F5F5F5;
    --dark-shadow: #171C2D;

    --gray-dark: #96A5B9;
    --gray-light: #E6E8ED;
    --gray-lighter: #F0F1F4;

    --ox-blue: #3B455A;
    --ebony-clay: #283041;

    --divider: #283041;

    --background-tertiary: #F0F1F4;

    --gray-darker: #9B9B9B;
    --gray-shadow: #92A1C1;

    --ox-blue: #3B455A;
    --ebony-clay: #283041;

    --divider-2: #BABAD1;

    --switch-border-color: #479EF8;
    --profile-border: #E1E6EB;
    --post-bar-border: #E1E6EB;

    --hover-1: #F5F5F5;

    --background-skeleton: #F5F5F5;
    --hightlight-skeleton: #EEEEEE;

    --input-bg: var(--gray-lighter);

    --modal: rgba(192, 201, 213, 0.97);

    --background-badge: #F5F5F5;
    --text-badge: #0BC7E0;

    --tag-remove: #96A5B9;

    --shared-post: #F0F9FF;

    --modalTransparentBackground: rgba(245, 245, 245, 0.85);
    --userRatingOverlayBackground: rgba(245, 245, 245, 0.5);

    --progress-track: #F0F1F4;

    --send-comment-button: #009BFF;
    --pencil-color: #009EFF;
    --off-white: #F5F5F5;

    /* tags */
    --tag-green: #0FF077;
    --tag-unrated: #171C2D;
    --tag-selected: #171C2D;
}

.dark {
    --background: #212636;
    --background-secondary: #000000;
    --background-primary: #171C2D;
    --background-dark: #171C2D;

    --primary: #00A0FF;
    --accent: #0BC7E0;
    --error: #F03C11;

    --text-base: #F5F5F5;
    --text-title: #F5F5F5;
    --shadow: #171C2D;
    --dark-shadow: #171C2D;

    --gray-dark: #96A5B9;
    --gray-light: #E6E8ED;
    --gray-lighter: #F0F1F4;

    --ox-blue: #3B455A;
    --ebony-clay: #283041;

    --divider: #283041;

    --background-tertiary: #171C2D;

    --gray-darker: #9B9B9B;
    --gray-shadow: #92A1C1;

    --ox-blue: #3B455A;
    --ebony-clay: #283041;

    --divider-2: #BABAD142;

    --switch-border-color: #0BC7E0;
    --profile-border: #283041;
    --post-bar-border: #171C2D;

    --hover-1: #212636;

    --background-skeleton: #1D2233;
    --hightlight-skeleton: #171C2D;

    --input-bg: var(--ebony-clay);

    --modal: rgba(23, 28, 45, 0.97);

    --background-badge: #0BC7E0;
    --text-badge: #171C2D;

    --tag-remove: #F5F5F5;

    --shared-post: #363D4E;

    --modalTransparentBackground: rgba(33, 38, 54, 0.85);
    --userRatingOverlayBackground: rgba(23, 28, 45, 0.5);

    --progress-track: #283041;

    --send-comment-button: #009BFF;
    --pencil-color: #009EFF;
    --off-white: #F5F5F5;

    /* tags */
    --tag-green: #0FF077;
    --tag-unrated: #171C2D;
    --tag-selected: #F5F5F5;
}